@use "info" as *;
@use "testimonial" as *;

.card {
  display: flex;
  flex-direction: column;
  position: relative;

  &[info] {
    @include info;
  }
  &[testimonial] {
    @include testimonial;
  }
}
