.container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 5% 1%;

  &[gradient] {
    background: linear-gradient(hsla(0, 0, 0, 0.7), hsla(0, 0, 0, 0.5));
  }
}
