@use "mixins/breakpoints" as *;
@use "../variables/" as *;

header.page-header {
  height: 100vh;
  background-image: url(../images/graduation.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  position: relative;

  .gradient {
    height: 100%;
    background: linear-gradient(hsla(0%, 0%, 0%, 0.7), hsla(0%, 0%, 0%, 0.5));
    padding: 7% 5%;
    text-align: right;

    @include breakpoint(desktop) {
      padding: 1% 2%;
    }
  }

  h1 {
    font-size: 3rem;
    line-height: 3.5rem;
    font-size: 15vw;
    line-height: 10vh;

    @include breakpoint(tablet) {
      line-height: 12vh;
    }

    @include breakpoint(desktop-half) {
      font-size: 12vw;
      line-height: 12vh;
    }

    @include breakpoint(desktop) {
      font-size: 7vw;
      line-height: 13vh;
    }

    @include breakpoint(desktop-lg) {
      font-size: 8vw;
      line-height: 14vh;
    }

    @include breakpoint(desktop-1080) {
      font-size: 7vw;
      line-height: 14vh;
    }
  }

  .sub-title {
    margin: 10% 0;

    @include breakpoint(desktop-half) {
      margin: 5% 0;
    }

    @include breakpoint(desktop-half) {
      margin: 1% 0;
    }

    h3 {
      margin: 3% 0;
      font-size: 1.5rem;
      font-size: 9vw;

      @include breakpoint(tablet) {
        font-size: 6vw;
        line-height: 5vh;
      }

      @include breakpoint(desktop) {
        font-size: 3.5vw;
        line-height: 4vh;
      }

      @include breakpoint(desktop-lg) {
        font-size: 3vw;
        line-height: 3vh;
      }
    }
  }

  .header-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(desktop-half) {
      justify-content: flex-end;
      margin: 2% 0;
    }

    .make-appointment {
      font-size: medium;

      &:after {
        content: "Let's Meet!";
      }

      @include breakpoint(phone-lg) {
        font-size: 2rem;
        padding: 1rem;
      }

      @include breakpoint(tablet) {
        width: 60vw;
        height: 10vw;
        font-size: 2rem;
      }

      @include breakpoint(desktop-half) {
        &:after {
          content: "Schedule a Meeting";
        }
      }

      @include breakpoint(desktop) {
        padding: 1%;
        height: auto;
      }

      @include breakpoint(desktop) {
        width: auto;
        height: auto;
        padding: 0.5rem 1rem;
        align-self: flex-end;
      }

      @include breakpoint(desktop-lg) {
        padding: 1rem 1.5rem;
        font-size: 3rem;
      }
    }

    #more_info {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      color: white;
      order: -1;

      img {
        transform: rotate(-90deg) scale(0.7);
        filter: invert(1);

        @keyframes bobbing {
          from {
            transform: translateY(10%) rotate(-90deg) scale(0.7);
          }
          50% {
            transform: translateY(-20%) rotate(-90deg) scale(0.7);
          }
          to {
            transform: translateY(10%) rotate(-90deg) scale(0.7);
          }
        }

        animation-name: bobbing;
        animation-duration: 2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
      }

      @include breakpoint(desktop-half) {
        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      }

      @include breakpoint(desktop-1080) {
        font-size: 2vw;
      }
    }
  }
}
