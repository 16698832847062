@use "./reset.css";

// Variables
@use "variables" as *;

// Components
@use "components";

*,
*:after,
*:before {
  font-family: "Noto Sans", sans-serif;
  font-weight: 100;
  text-decoration: none;
  box-sizing: border-box;
}

main {
  height: 100%;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.945);
}
