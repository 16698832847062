@use "../mixins/shadows" as *;
@use "../mixins/breakpoints" as *;

@mixin testimonial {
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include breakpoint(desktop-half) {
    padding-bottom: 3%;
    width: 40%;
  }

  @include breakpoint(desktop) {
    padding: 1% .1%;
  }

  header {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    img {
      height: 25vh;
      margin-bottom: 3vh;
    }

    @include breakpoint(desktop-half) {
      img {
        height: 15vh;
      }
    }

    .name,
    .school {
      position: absolute;
      padding: 1% 10%;
      width: fit-content;
      font-size: large;
      background-color: white;

      @include shadow;
    }

    .name {
      left: 5%;
      top: 45%;
    }

    .school {
      left: 10%;
      top: 70%;
    }

    @include breakpoint(phone-md) {
      .name {
        top: 50%;
      }
    }

    @include breakpoint(desktop-half) {
      .name,
      .school {
        font-size: large;
      }
    }
  }

  p {
    padding: 0 5%;

    @include breakpoint(desktop) {
      font-size: 1vw;
      line-height: 2.1vh;
    }
  }
}
