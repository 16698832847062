@use "mixins/shadows" as *;
@use "mixins/breakpoints" as *;

.testimonial-section {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include breakpoint(desktop) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .side-header {
    height: 30vh;
    position: relative;
    background-image: url(../images/writing_outside.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;

    @include breakpoint(desktop) {
      height: 100vh;
      width: 40%;

      background-position: -50vw;

      .overlay {
        // background-color: hsla(0, 0, 0, 0.5);
      }
    }

    header {
      z-index: 999;
      position: absolute;
      left: 5%;
      bottom: -5%;
      padding: 2% 5%;
      background-color: white;

      h1 {
        font-size: xx-large;
        font-variant: small-caps;

        @include breakpoint(desktop) {
          font-size: 6vw;
        }
      }

      @include shadow;

      @include breakpoint(desktop) {
        bottom: unset;
        top: 10%;
        left: 60%;
        width: fit-content;
        white-space: nowrap;
      }
    }
  }

  .cards {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

    @include breakpoint(desktop-half) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include breakpoint(desktop) {
      flex-wrap: nowrap;
      width: 60%;
      margin-top: 10%;
    }
  }

  &[flip] {
    .side-header {
      background-image: url(../images/graduation_crowd.jpg);
      background-position: 0 50%;

      header {
        background-color: black;

        @include breakpoint(desktop) {
          left: unset;
          right: 60%;
        }

        h1 {
          color: white;
          @include breakpoint(desktop-half, max) {
            font-size: x-large;
          }
        }
      }

      @include breakpoint(desktop) {
        order: 1;
      }
    }

    .card[testimonial] {
      .name,
      .school {
        background-color: black;
        color: white;
        left: unset;
      }

      .name {
        right: 5%;
      }

      .school {
        right: 10%;
      }
    }
  }
}
