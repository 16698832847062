@use '../variables/colors' as *;
@use '../variables/formatting' as *;
@use "./mixins/shadows"  as *;

button {
  outline: none;
  border: none;
  border-radius: $radius;
  background-color: $main;
  padding: $padding * (0.75);
  font-size: large;
  transition: box-shadow 0.1s ease, background-color 0.1s ease,
    transform 0.2s ease;

  @include shadow;

  &[clear] {
    background-color: transparent;
    box-shadow: none;
  }

  &:hover:not([clear]) {
    transform: translateY(-5%);
    box-shadow: 0.2rem 0.2rem 0.8rem 0rem $shadow;
    background-color: lighten($main, 5%);
  }

  &:active:not([clear]) {
    transform: translateY(0);
    box-shadow: 0rem 0rem 0rem 0rem $shadow;
    background-color: darken($main, 5%);
  }
}
