@use "../mixins/breakpoints" as *;
@use "../mixins/shadows" as *;

// TODO Change to fit the site style
@mixin info {
  margin: 2% 5% 5% 5%;

  @include breakpoint(tablet) {
    max-width: 70%;
  }

  @include breakpoint(desktop-half) {
    max-width: 70%;
    margin: 0 1%;
    padding: 1% 2%;
  }

  @include breakpoint(desktop) {
    max-width: 80%;
  }

  @include breakpoint(desktop-1080) {
    max-width: 60%;
  }

  header {
    font-size: xx-large;
    text-align: center;
    text-transform: capitalize;
    font-variant: small-caps;
    margin-bottom: 0.5rem;

    @include breakpoint(desktop-half) {
      font-size: x-large;
    }

    @include breakpoint(desktop) {
      font-size: xx-large;
    }

    @include breakpoint(desktop-1080) {
      font-size: xxx-large;
    }
  }

  p {
    padding: 1rem 1% 0 1%;
    border-top: 0.13rem solid black;

    @include breakpoint(tablet) {
      line-height: 2vh;
    }

    @include breakpoint(desktop) {
      font-size: x-large;
      line-height: 1.8rem;
    }
  }
}
